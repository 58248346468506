(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
// Scrolle ich grade?
var scrolling = false;

// Wie triggern wir das Scroll Event?
$(window).on('scroll', function () {
  if (!scrolling) {
    scrolling = true;
    ud_hide_sub_menu();
  }

});

// Verstecken des Headers!
function ud_hide_sub_menu() {

  var el = $('.site__header--bar');
  var ud_aktuelle_pos = el.offset().top;

  //console.log(ud_aktuelle_pos);

  if (ud_aktuelle_pos > 200) {

    console.log('hello');
    el.addClass('js-topnav-close');
    $('.site__header--topbar').hide();
    $('header').addClass('js__scroll-active');

  } else {

    el.removeClass('js-topnav-close');
    $('header').removeClass('js__scroll-active');
    $('.site__header--topbar').show();
  }

  scrolling = false;

}

}).call(this,require("rH1JPG"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/modul/lightFunktion.js","/modul")