(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
/**
 * Created by Wuertenberger Design on 21.09.18.
 */

//var $ = jQuery.noConflict();

function hamburger() {
    // Mobile Navigation
    $('.site__header-menu-icon').on('click', function (e) {

        var parEl = $('.site__header--icon');
        var elNav = $('.site__header--menu');
        var newClass = 'site__menu--open';

        elNav.slideToggle('fast');

        if (parEl.hasClass(newClass)) {
            parEl.removeClass(newClass);
        } else {
            parEl.addClass(newClass);
        }
    });

}

$(document).ready(function () {
    hamburger();
});
}).call(this,require("rH1JPG"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/modul/hamburger.js","/modul")